import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import ChildPage from "../components/layout/page"

import Section  from "../components/layout/section"
import { FixedContainer } from "../components/layout/container"

const moment = require(`moment`)
const config = require("../../content/data/SiteConfig")

const NewsPage = ({data}) => {
  const { allMarkdownRemark, header } = data
  const headerImage = header.publicURL
  
  const news = allMarkdownRemark.edges

  const parallax = {
    image: headerImage,
    strength: 100
  }

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "news", "title": "News", "path": "/news" }
  ]
  return (
    <ChildPage 
      title="News" 
      subtitle="Latest Updates from the Group"
      breadcrumbs={breadcrumbs}
      parallax={parallax}
    >
      <Section>
        <FixedContainer>
          <div className="posts">
            {news && news.map( post => {
              const { node } = post
              const { frontmatter, html } = node
              const { date, image } = frontmatter
              const fixed = getImage(image)
              
              const parsedDate = moment(date, "MM/DD/YYYY")

              return (
                <div className="media post">
                  <div className="media-left">
                    <GatsbyImage image={fixed} />
                  </div>
                  <div className="media-content">
                    <div className="content">
                      <p className="title is-6">{frontmatter.title}</p>
                      <p className="subtitle is-6 is-small">{parsedDate.format("MMMM Do YYYY")}</p>
                      <div dangerouslySetInnerHTML={{ __html: html }} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </FixedContainer>
      </Section>
    </ChildPage>
  );
}

export default NewsPage

export const pageQuery = graphql`query NewsPage {
  header: file(relativePath: {eq: "news.jpg"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMarkdownRemark(filter: {fields: {type: {eq: "news"}}}) {
    edges {
      node {
        html
        frontmatter {
          title
          date
          image {
            childImageSharp {
              gatsbyImageData(width: 200, layout: FIXED)
            }
          }
        }
        fields {
          slug
          type
          date
        }
      }
    }
  }
}
`